import React, { ChangeEvent } from 'react';
import { defineMessages } from 'react-intl';
import FormGroup from '@cjdev-internal/visual-stack/lib/components/Form/FormGroup';
import Input from '@cjdev-internal/visual-stack/lib/components/Form/Input';
import Label from '@cjdev-internal/visual-stack/lib/components/Form/Label';
import intl from '../../utils/intl';
import {
  AlertModalProps,
  ModalMountPoint,
  ModalState,
} from '../ModalMountPoint';
import { GenericModal } from '../GenericModal';
import {
  CreateUniversalTag,
  isUniversalTag,
  MaybeTrackedValue,
  UniversalTag,
} from 'atossa-core';
import * as R from 'ramda';
import { ApiProps } from '../MainPage';
import { sanitizeNameUrl } from '../../utils/trackedValue';

export interface TagParams {
  id?: string;
  name: string; // inputs on tag modals can not be undefined in react
  url: string;
}

export const TagModal = (props: AlertModalProps<TagParams>) => {
  return (
    <GenericModal {...props}>
      {({ modalFormState, setModalFormState }) => {
        const { name, url } = modalFormState;
        return (
          <>
            <FormGroup vertical={false}>
              <Label htmlFor="name">
                {intl.formatMessage(content.tagModalNameLabel)}
              </Label>
              <Input
                id="name"
                value={name}
                onChange={(name: ChangeEvent<HTMLInputElement>) =>
                  setModalFormState({
                    ...modalFormState,
                    name: name.target.value,
                  })
                }
                autoFocus={true}
              />
            </FormGroup>
            <FormGroup vertical={false}>
              <Label htmlFor="url">
                {intl.formatMessage(content.tagModalUrlLabel)}
              </Label>
              <Input
                id="url"
                value={url}
                onChange={(url: ChangeEvent<HTMLInputElement>) =>
                  setModalFormState({
                    ...modalFormState,
                    url: url.target.value,
                  })
                }
              />
            </FormGroup>
          </>
        );
      }}
    </GenericModal>
  );
};

const createTag = (
  setTags: React.Dispatch<MaybeTrackedValue<UniversalTag>[]>,
  { api, cid }: ApiProps
) => async (tag: CreateUniversalTag) => {
  const response = await api.createTag(cid, tag);
  setTags(response.tags);
};

const editTag = (
  setTags: React.Dispatch<MaybeTrackedValue<UniversalTag>[]>,
  { api, cid }: ApiProps
) => async (tag: TagParams) => {
  if (isUniversalTag(tag)) {
    const response = await api.editTag(cid, tag);
    setTags(response.tags);
  } else {
    throw new Error(`TagParam ${tag} was missing 'id'`);
  }
};

const initialTag: TagParams = {
  name: '',
  url: '',
};

export const CreateTagModalMountPoint = ({
  modalState,
  setModalState,
  setTags,
  apiProps,
}: {
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
  setTags: React.Dispatch<MaybeTrackedValue<UniversalTag>[]>;
  apiProps: ApiProps;
}) => {
  return (
    <ModalMountPoint
      modalState={modalState}
      ModalComponent={TagModal}
      modalProps={{
        labels: {
          modalHeader: intl.formatMessage(content.createTagModalHeader),
          actButton: intl.formatMessage(content.createTagModalCreateButton),
          actingButton: intl.formatMessage(
            content.createTagModalCreatingButton
          ),
          alertSuccess: intl.formatMessage(content.tagCreatedSuccessAlert),
          alertFail: intl.formatMessage(content.tagCreatedFailAlert),
        },
        initialModalFormState: initialTag,
        action: createTag(setTags, apiProps),
        setModalState,
      }}
    />
  );
};

export const EditTagModalMountPoint = ({
  modalState,
  setModalState,
  setTags,
  apiProps,
  tag,
}: {
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
  setTags: React.Dispatch<MaybeTrackedValue<UniversalTag>[]>;
  apiProps: ApiProps;
  tag: TagParams;
}) => {
  const emptyTagParams: TagParams = { name: '', url: '' };
  return (
    <ModalMountPoint
      modalState={modalState}
      ModalComponent={TagModal}
      modalProps={{
        validate: (current: TagParams, initial: TagParams) =>
          !R.equals(sanitizeNameUrl(current) as unknown, initial as unknown),
        labels: {
          modalHeader: intl.formatMessage(content.editTagModalHeader),
          actButton: intl.formatMessage(content.editTagModalSaveButton),
          actingButton: intl.formatMessage(content.editTagModalSavingButton),
          alertSuccess: intl.formatMessage(content.tagSavedSuccessAlert),
          alertFail: intl.formatMessage(content.tagSavedFailAlert),
        },
        initialModalFormState: { ...emptyTagParams, ...tag },
        action: editTag(setTags, apiProps),
        setModalState,
      }}
    />
  );
};

export const content = defineMessages({
  createTagModalHeader: {
    id: 'atossa.createmodal.header',
    defaultMessage: 'Create Tag',
  },
  tagModalNameLabel: {
    id: 'atossa.tagmodal.name.label',
    defaultMessage: 'Name',
  },
  tagModalUrlLabel: {
    id: 'atossa.tagmodal.url.label',
    defaultMessage: 'Website URL',
  },
  createTagModalCreateButton: {
    id: 'atossa.createmodal.create.button',
    defaultMessage: 'Create',
  },
  createTagModalCreatingButton: {
    id: 'atossa.createmodal.creating.button',
    defaultMessage: 'Creating...',
  },
  tagCreatedSuccessAlert: {
    id: 'atossa.tagcreated.success.alert',
    defaultMessage: 'Tag created.',
  },
  tagCreatedFailAlert: {
    id: 'atossa.tagcreated.fail.alert',
    defaultMessage: 'Tag creation failed.',
  },
  editTagModalHeader: {
    id: 'atossa.editmodal.header',
    defaultMessage: 'Edit Tag',
  },
  editTagModalSaveButton: {
    id: 'atossa.editmodal.save.button',
    defaultMessage: 'Save',
  },
  editTagModalSavingButton: {
    id: 'atossa.editmodal.saving.button',
    defaultMessage: 'Saving...',
  },
  tagSavedSuccessAlert: {
    id: 'atossa.tagsaved.success.alert',
    defaultMessage: 'Changes saved.',
  },
  tagSavedFailAlert: {
    id: 'atossa.tagsaved.fail.alert',
    defaultMessage: 'Changes failed.',
  },
});
