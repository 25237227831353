import React, { useEffect, useState } from 'react';
import LoadingAnimation from '@cjdev-internal/visual-stack/lib/components/LoadingAnimation';
import * as R from 'ramda';
import { Api } from 'Api/api';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { AdvertiserTagView } from '../AdvertiserTagView';
import { ModalState } from '../ModalMountPoint';
import {
  Advertiser,
  assertNever,
  MaybeTrackedValue,
  UniversalTag,
} from 'atossa-core';
import { History } from 'history';
import {
  CreateTagModalMountPoint,
  EditTagModalMountPoint,
  TagParams,
} from '../TagModal';
import { ToggleCjDataModalMountPoint } from '../ToggleCjDataModal';
import styled from 'styled-components';
import { BlankPage } from 'Components/BlankPage';
import { TopNav } from '@cjdev-internal/visual-stack/lib/components/TopNav';
import { Button } from '@cjdev-internal/visual-stack/lib/components/Button';
import { AffApiCompany } from 'atossa-core';
import { appConfig } from '../../appConfig';

const StyledDiv = styled.div`
  text-align: center;
`;
const goBack  = (doc: Document) => {
  const to =  doc.referrer || `${appConfig.memberBase}/member/advertiser/home.do`;
  doc.location.assign(to);
}
const StyledTopNav = styled(TopNav)`
  max-width: 1000px;
  margin: auto;
`;

export interface ApiProps {
  api: Api;
  cid: string;
}

export interface MainPageProps extends ApiProps {
  history: History<unknown>;
  doc: Document;
}

type PageState =
  | { state: 'Loading' }
  | { state: 'Error'; error: Error }
  | { state: 'Success' };

export interface Pagination {
  page: number;
  rowsPerPage: number;
}

const defaultPagination: Pagination = {
  page: 1,
  rowsPerPage: 10,
};

export const MainPage = (props: MainPageProps) => {
  const [company, setCompany] = useState<Pick<
    AffApiCompany,
    'name' | 'id'
  > | null>(null);
  const [pagination, setPagination] = useState(defaultPagination);
  const { api, cid } = props;

  const [advertiser, setAdvertiser] = useState<Advertiser>({
    cid,
    cjDataEnabled: { value: false },
    tags: [],
  });
  const setCjDataEnabled = (cjDataEnabled: MaybeTrackedValue<boolean>) => {
    setAdvertiser({ ...advertiser, cjDataEnabled });
  };
  const setTags = (tags: MaybeTrackedValue<UniversalTag>[]) => {
    setAdvertiser({ ...advertiser, tags });
  };
  const [pageState, setPageState] = useState<PageState>({
    state: 'Loading',
  });
  const [toggleCjDataModalState, setToggleCjDataModalState] = useState<
    ModalState
  >({
    state: 'Hidden',
  });
  const [createTagModalState, setCreateTagModalState] = useState<ModalState>({
    state: 'Hidden',
  });
  const [tagUnderEdit, setTagUnderEdit] = useState<TagParams>({
    name: '',
    url: '',
  });
  const [editTagModalState, setEditTagModalState] = useState<ModalState>({
    state: 'Hidden',
  });

  useEffect(() => {
    (async () => {
      setPageState({ state: 'Loading' });
      try {
        const companyPromise = api.fetchCompany(cid);
        const advertiserPromise = api.fetchAdvertiser(cid);
        const [company, advertiser] = await Promise.all([
          companyPromise,
          advertiserPromise,
        ]);

        if (advertiser) {
          setAdvertiser(advertiser);
        }
        setCompany(company);
        setPageState({ state: 'Success' });
      } catch (failure) {
        let error;
        if (failure instanceof Error) {
          error = failure;
        } else {
          error = new Error(String(failure))
        }
        setPageState({ state: 'Error', error });
      }
    })();
  }, [api, cid]);

  interface ContentProps {
    pageState: PageState;
  }

  const MainContent = (contentProps: ContentProps) => {
    switch (contentProps.pageState.state) {
      case 'Loading':
        return (
          <StyledDiv>
            <LoadingAnimation
              loadingMessage={intl.formatMessage(
                content.tagTableLoadingMessage
              )}
            />
          </StyledDiv>
        );
      case 'Error':
        return (
          <StyledDiv>
            <span>{intl.formatMessage(content.tagTableErrorMessage)}</span>
            <br />
            {contentProps.pageState.error.message}
          </StyledDiv>
        );
      case 'Success':
        if (R.isNil(advertiser.tags) || !advertiser.tags.length) {
          return (
            <BlankPage
              openCreateTagModal={() =>
                setCreateTagModalState({ state: 'Open' })
              }
            />
          );
        } else {
          return (
            <AdvertiserTagView
              advertiser={advertiser}
              pagination={pagination}
              setPagination={setPagination}
              openToggleCjDataModal={() =>
                setToggleCjDataModalState({ state: 'Open' })
              }
              openCreateTagModal={() =>
                setCreateTagModalState({ state: 'Open' })
              }
              openEditTagModal={(tag: TagParams) => {
                setEditTagModalState({ state: 'Open' });
                setTagUnderEdit(tag);
              }}
            />
          );
        }
      default:
        assertNever(contentProps.pageState);
    }
  };
  const topNavActions = (
    <Button
      id="vs-dialog-layout-cancel"
      type="text"
      onClick={() => goBack(props.doc)}
    >
      {intl.formatMessage(content.appBackButton)}
    </Button>
  );
  return (
    <>
      <StyledTopNav
        title={intl.formatMessage(content.appTitle)}
        subtitle={company ? `${company.name} - ${company.id}` : ''}
        actionChildren={topNavActions}
        children={<MainContent pageState={pageState} />}
        contentSize="wide"
      />
      <ToggleCjDataModalMountPoint
        modalState={toggleCjDataModalState}
        setModalState={setToggleCjDataModalState}
        cjDataEnabled={advertiser.cjDataEnabled}
        setCjDataEnabled={setCjDataEnabled}
        apiProps={{ api, cid }}
      />
      <CreateTagModalMountPoint
        modalState={createTagModalState}
        setModalState={setCreateTagModalState}
        setTags={setTags}
        apiProps={{ api, cid }}
      />
      <EditTagModalMountPoint
        modalState={editTagModalState}
        setModalState={setEditTagModalState}
        setTags={setTags}
        apiProps={{ api, cid }}
        tag={tagUnderEdit}
      />
    </>
  );
};

export const content = defineMessages({
  appTitle: {
    id: 'atossa.layout.title',
    defaultMessage: 'Tag Management',
  },
  appBackButton: {
    id: 'atossa.layout.button',
    defaultMessage: 'Back to Account Manager',
  },
  tagTableErrorMessage: {
    id: 'atossa.tagTable.error',
    defaultMessage: 'Something went wrong!',
  },
  tagTableLoadingMessage: {
    id: 'atossa.tagTable.loading',
    defaultMessage: 'Loading',
  },
});
