import { useEffect } from 'react';
import Cookies from 'js-cookie';
import Querystring from 'querystring';
import { History } from 'history';

interface OAuthStuff {
  redirectUrl: string;
  companyId: string;
}

const getParsedUrl = (uri: string): Querystring.ParsedUrlQuery => {
  const url = new URL(uri);
  if (!url.hash && !url.search) {
    throw new TypeError(`Unable to process uri: ${uri}`);
  }
  const data = Object.assign(
    {},
    Querystring.parse(url.search.substr(1)),
    Querystring.parse(url.hash.substr(1))
  );
  return data;
};

const getOauthData: (href: string) => Promise<OAuthStuff> = async (href) => {
  const { state } = getParsedUrl(href);
  if (typeof state != 'string') {
    throw new Error(`Unexpected state: ${state}`);
  }
  const { redirectUrl, companyId } = JSON.parse(atob(state));
  return { redirectUrl, companyId };
};

export const OauthCallback: ({
  history,
}: {
  history: History;
}) => JSX.Element = ({ history }) => {
  useEffect(() => {
    (async () => {
      const { redirectUrl, companyId } = await getOauthData(
        window.location.href
      );
      Cookies.set('utm_jsCompanyId', companyId);
      history.replace(redirectUrl);
    })();
  }, [history]);

  return <div />;
};
