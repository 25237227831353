import { appConfig } from '../appConfig';
import {
  Advertiser,
  AffApiCompany,
  CreateUniversalTag,
  isAdvertiser,
  isAffApiUser,
  UniversalTag,
} from 'atossa-core';
import { sanitizeNameUrl } from '../utils/trackedValue';
import { getBearerToken } from '@platform/platform-auth';

export type Api = {
  fetchCompany: (cid: string) => Promise<AffApiCompany>;
  fetchAdvertiser: (cid: string) => Promise<Advertiser | null>;
  setCjDataEnabled: (
    cid: string,
    cjDataEnabled: boolean
  ) => Promise<Advertiser>;
  createTag: (cid: string, tag: CreateUniversalTag) => Promise<Advertiser>;
  editTag: (cid: string, tag: UniversalTag) => Promise<Advertiser>;
};

const get: (url: RequestInfo, token: string) => Promise<Response> = (
  url,
  token
) => {
  return fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

type TokenVerifyResponseBody = {
  userId: string;
};

const isTokenVerifyResponseBody = (
  object: unknown
): object is TokenVerifyResponseBody => {
  const tokenVerifyResponseBody = object as TokenVerifyResponseBody;
  return (
    tokenVerifyResponseBody &&
    typeof tokenVerifyResponseBody.userId === 'string'
  );
};

export const api: Api = {
  fetchCompany: async (cid: string) => {
    const token = await getBearerToken();
    const userIdResponse = await get(
      'https://members.cj.com/affapi/token/verify',
      token
    );
    const { userId } = await handleResponse(
      userIdResponse,
      isTokenVerifyResponseBody
    );
    // `includeActiveOnlyForBackwardsCompatibility` includes "Accounts in setup" as part of the response
    const userResponse = await get(
      `https://members.cj.com/affapi/oauth/user/${userId}?includeActiveOnlyForBackwardsCompatibility=false`,
      token
    );
    const user = await handleResponse(userResponse, isAffApiUser);
    const company = user.companies.find(
      (company) => company.id.toString() === cid
    );
    if (!!company) {
      return company;
    }
    throw new Error(`User is not associated with company: ${cid}`);
  },
  fetchAdvertiser: async (cid: string) => {
    const token = await getBearerToken();
    const response = await fetch(`${appConfig.apiUrl}advertisers/${cid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 404) {
      return null;
    }
    return handleResponse(response, isAdvertiser);
  },
  setCjDataEnabled: async (cid: string, cjDataEnabled: boolean) => {
    const token = await getBearerToken();
    const response = await fetch(`${appConfig.apiUrl}advertisers/${cid}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ cjDataEnabled }),
    });
    return handleResponse(response, isAdvertiser);
  },
  createTag: async (cid: string, tag: CreateUniversalTag) => {
    const token = await getBearerToken();
    const response = await fetch(`${appConfig.apiUrl}advertisers/${cid}/tags`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(sanitizeNameUrl(tag)),
    });
    return handleResponse(response, isAdvertiser);
  },
  editTag: async (cid: string, tag: UniversalTag) => {
    const token = await getBearerToken();
    const response = await fetch(
      `${appConfig.apiUrl}advertisers/${cid}/tags/${tag.id}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(sanitizeNameUrl({ name: tag.name, url: tag.url })),
      }
    );
    return handleResponse(response, isAdvertiser);
  },
};
type ErrorCode = 400 | 403 | 404 | 405 | 500 | 501 | 502 | 503 | 504 | 999;
function toErrorCode(n: number): ErrorCode {
  if (
    400 === n ||
    403 === n ||
    404 === n ||
    405 === n ||
    500 === n ||
    500 === n ||
    500 === n ||
    500 === n ||
    500 === n
  ) {
    return n;
  }
  return 999;
}

export class HttpError extends Error {
  public statusCode: ErrorCode;
  constructor(message: string, statusCode: number = 999) {
    super(message);
    this.statusCode = toErrorCode(statusCode);
  }
}
const handleResponse = async <T>(
  response: Response,
  isT: (obj: unknown) => obj is T
): Promise<T> => {
  if (response.status === 200) {
    const t = await response.json();
    if (isT(t)) {
      return t;
    } else {
      return Promise.reject(
        new HttpError(
          `Unexpected response: status (${
            response.status
          }) : body (${JSON.stringify(t)})`,
          response.status
        )
      );
    }
  } else {
    try {
      const text = await response.text();
      return Promise.reject(
        new HttpError(`${response.status} : ${text}`, response.status)
      );
    } catch (e) {
      return Promise.reject(
        new HttpError(`${response.status}`, response.status)
      );
    }
  }
};
