const authConfig = () => {
  const prod = {
    clientId: '',
    authorizationUri:
      'https://members.cj.com/member/login?appName=integrations&path=/',
  };

  const dev = {
    clientId: '',
    authorizationUri:
      'https://member107.lab.cj.com/member/login?appName=integrations&path=/',
  };

  const local = {
    clientId: '',
    authorizationUri: 'https://local.cj.com/member/login',
  };

  // @ts-ignore
  const hostname = window.location.hostname;
  return hostname.includes('local')
    ? local
    : hostname.includes('cats.dev.cj.com')
    ? dev
    : prod;
};

export default authConfig;
