import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components';
import { errorMessage } from '../../utils/errorMessage';
import { Login } from '../Login';
import { OauthCallback } from '../Login/OauthCallback';
import { MainPage } from '../MainPage';
import { Body } from '@cjdev-internal/visual-stack/lib/components/Panel';
import { LoadingAnimation } from '@cjdev-internal/visual-stack-x/LoadingAnimation';
import { Api } from '../../Api/api';
import { PrivateRoute } from '../Login/PrivateRoute';
import NotFound from '../Login/NotFound';
import { MainPageNewNav } from '../NewNav';
import {
  PlatformApp,
  shouldDisplay2022Nav,
  useNavigate,
} from '@platform/cj-platform-navigation';
import '@cjdev-internal/design-tokens';

// design tokens are css variables that VSX depends on
export type AppDependencies = {
  api: Api;
  doc: Document;
};

const CenteredLoadingAnimation = styled(LoadingAnimation)`
  margin: auto;
  display: block !important;
`;

const Content = ({ api, doc }: AppDependencies) => {
  const history = useHistory();
  const TagPage = (props: { cid: string }) => (
    <MainPage doc={doc} cid={props.cid} api={api} history={history} />
  );

  return (
    <>
      <Body>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/oauth-callback" component={OauthCallback} />
          <Route exact path={'/'} component={() => <Redirect to="/list" />} />
          <PrivateRoute path="/list" component={TagPage} />
          <Route component={NotFound} />
        </Switch>
      </Body>
    </>
  );
};

type PageState =
  | { state: 'Loading' }
  | { state: 'Error'; error: Error }
  | { state: 'Success' };

export const App = (dependencies: AppDependencies) => {
  const history = useHistory();

  const layout = {
    filters: true,
    sidenav: true,
    dimensions: true,
    pageTitle: true,
    Buttons: true,
  };

  const routes = [
    {
      layout,
      path: '/',
      titleMessage: {
        id: 'nav-home',
        defaultMessage: 'Home',
      },
      Content: () => {
        const navigate = useNavigate();
        navigate('/list');
        return <></>;
      },
    },
    {
      layout,
      path: '/list',
      titleMessage: {
        id: 'nav-root',
        defaultMessage: 'Tag Management',
      },
      Content: ({ user }: any) => (
        <MainPageNewNav
          api={dependencies.api}
          history={history}
          doc={dependencies.doc}
          user={user}
        />
      ),
    },
  ];
  const translations = {};
  const [isNewNav, setIsNewNav] = useState(false);
  const [isLoading, setIsLoading] = useState<PageState>({ state: 'Loading' });
  useEffect(() => {
    setIsLoading({ state: 'Loading' });
    (async () => {
      try {
        const flag = await shouldDisplay2022Nav();
        setIsNewNav(flag);
        setIsLoading({ state: 'Success' });
      } catch (error) {
        const message = errorMessage(error);
        setIsLoading({ state: 'Error', error: new Error(message) });
      }
    })();
  }, [isNewNav]);
  switch (isLoading.state) {
    case 'Success':
      if (isNewNav) {
        return (
          <Router>
            <PlatformApp routes={routes} translations={translations} />
          </Router>
        );
      }
      return (
        <Router>
          <Content api={dependencies.api} doc={dependencies.doc} />
        </Router>
      );
    case 'Loading':
      return <CenteredLoadingAnimation />;
    case 'Error': // in case of error, we default to old nav
      return (
        <Router>
          <Content api={dependencies.api} doc={dependencies.doc} />
        </Router>
      );
  }
};
