import ClientOAuth2 from 'client-oauth2';
import { useEffect } from 'react';
import authConfig from './authConfig';

const cjAuth = new ClientOAuth2(authConfig());

export const getAuthRedirectUri: () => string = () => {
  const state = { nonce: (+new Date()).toString(36) };
  return cjAuth.token.getUri({
    state: btoa(JSON.stringify(state)),
  });
};

export const Login: () => JSX.Element = () => {
  useEffect(() => {
    window.location.href = getAuthRedirectUri();
  }, []);
  return <div />;
};
