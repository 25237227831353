import React, { useState } from 'react';
import * as M from '@cjdev-internal/visual-stack/lib/components/Modal';
import Form from '@cjdev-internal/visual-stack/lib/components/Form/Form';
import {
  Button,
  SubmitButton,
} from '@cjdev-internal/visual-stack/lib/components/Button';
import Spinner from '@cjdev-internal/visual-stack/lib/components/Spinner';
import intl from '../../utils/intl';
import { AlertModalProps } from '../ModalMountPoint';
import { defineMessages } from 'react-intl';

export const GenericModal = <T,>({
  action,
  children,
  initialModalFormState,
  validate,
  labels: { actButton, actingButton, modalHeader },
  setAlertState,
  setModalState,
}: AlertModalProps<T>) => {
  const [acting, setActing] = useState(false);
  const [modalFormState, setModalFormState] = useState(initialModalFormState);
  const disabled =
    acting ||
    (validate ? !validate(modalFormState, initialModalFormState) : false);

  const cancel = () => {
    if (!acting) {
      setModalState({ state: 'Hidden' });
      setAlertState({ state: 'Hidden' });
    }
  };

  const act = async (e: Event) => {
    e.preventDefault(); // Prevent action from being called twice
    setActing(true);
    try {
      await action(modalFormState);
      setAlertState({ state: 'Success' });
      setModalState({ state: 'Hidden' });
    } catch (e) {
      setActing(false);
      setAlertState({ state: 'Failure' });
    }
  };

  if (typeof children !== 'function') {
    throw Error('No children!');
  }
  return (
    <M.Modal onBackgroundClick={cancel}>
      <M.Dialog>
        <M.Content>
          <Form vertical={false} onSubmit={act} disabled={acting}>
            <M.Header title={modalHeader} />
            <M.Body>{children({ modalFormState, setModalFormState })}</M.Body>
            <M.Footer>
              <Button type="text" onClick={cancel} disabled={acting}>
                {intl.formatMessage(content.genericModalCancelButton)}
              </Button>
              <SubmitButton
                type="solid-primary"
                onClick={act}
                disabled={disabled}
              >
                {acting ? (
                  <>
                    <Spinner size="button" />
                    {actingButton}
                  </>
                ) : (
                  actButton
                )}
              </SubmitButton>
            </M.Footer>
          </Form>
        </M.Content>
      </M.Dialog>
    </M.Modal>
  );
};

export const content = defineMessages({
  genericModalCancelButton: {
    id: 'atossa.modal.cancel.button',
    defaultMessage: 'Cancel',
  },
});
