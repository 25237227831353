import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCookie } from '../../utils/useCookie';
import { assertNever } from 'atossa-core';
import { getBearerToken } from '@platform/platform-auth';

type LoginStatus =
  | { state: 'Loading' }
  | { state: 'Failure' }
  | { state: 'Success'; cid: string };

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>({
    state: 'Loading',
  });
  const [cid] = useCookie('utm_jsCompanyId');
  useEffect(() => {
    (async () => {
      const token = await getBearerToken();
      const loginStatus: LoginStatus =
        token && cid
          ? {
              state: 'Success',
              cid,
            }
          : { state: 'Failure' };
      setLoginStatus(loginStatus);
    })();
  }, [cid]);

  switch (loginStatus.state) {
    case 'Loading':
      return <div />;
    case 'Success':
      return (
        <Route
          {...rest}
          render={(props) => <Component cid={loginStatus.cid} {...props} />}
        />
      );
    case 'Failure':
      return <Route {...rest} render={() => <Redirect to={'/login'} />} />;
    default:
      assertNever(loginStatus);
  }
};
