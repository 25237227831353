import React from 'react';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import {
  AlertModalProps,
  ModalMountPoint,
  ModalState,
} from '../ModalMountPoint';
import { GenericModal } from '../GenericModal';
import { MaybeTrackedValue } from 'atossa-core';
import { ApiProps } from '../MainPage';

export interface ToggleCjDataParams {
  cjDataEnabled: MaybeTrackedValue<boolean>;
}

export const ToggleCjDataModal = (
  props: AlertModalProps<ToggleCjDataParams>
) => (
  <GenericModal {...props}>
    {({ modalFormState }) => (
      <span>
        {intl.formatMessage(
          modalFormState.cjDataEnabled.value
            ? content.modalDisableBodyText
            : content.modalEnableBodyText,
          { cjdata: <code>cjdata</code> }
        )}
      </span>
    )}
  </GenericModal>
);

const toggleCjDataEnabled = (
  setCjDataEnabled: React.Dispatch<MaybeTrackedValue<boolean>>,
  apiProps: ApiProps
) => async ({ cjDataEnabled }: ToggleCjDataParams) => {
  const { api, cid } = apiProps;
  const response = await api.setCjDataEnabled(cid, !cjDataEnabled.value);
  setCjDataEnabled(response.cjDataEnabled);
};

export const ToggleCjDataModalMountPoint = ({
  modalState,
  setModalState,
  cjDataEnabled,
  setCjDataEnabled,
  apiProps,
}: {
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
  cjDataEnabled: MaybeTrackedValue<boolean>;
  setCjDataEnabled: React.Dispatch<MaybeTrackedValue<boolean>>;
  apiProps: ApiProps;
}) => {
  return (
    <ModalMountPoint
      modalState={modalState}
      ModalComponent={ToggleCjDataModal}
      modalProps={{
        labels: {
          modalHeader: intl.formatMessage(
            cjDataEnabled.value
              ? content.modalDisableHeader
              : content.modalEnableHeader
          ),
          actButton: intl.formatMessage(content.modalConfirmButton),
          actingButton: intl.formatMessage(
            cjDataEnabled.value
              ? content.modalDisablingButton
              : content.modalEnablingButton
          ),
          alertSuccess: intl.formatMessage(
            cjDataEnabled.value
              ? content.cjDataEnabledSuccessAlert
              : content.cjDataDisabledSuccessAlert
          ),
          alertFail: intl.formatMessage(
            cjDataEnabled.value
              ? content.cjDataEnabledFailAlert
              : content.cjDataDisabledFailAlert
          ),
        },
        initialModalFormState: {
          cjDataEnabled: cjDataEnabled,
        },
        action: toggleCjDataEnabled(setCjDataEnabled, apiProps),
        setModalState,
      }}
    />
  );
};

export const content = defineMessages({
  modalEnableHeader: {
    id: 'atossa.togglemodal.enable.header',
    defaultMessage: 'Enable CJ Data?',
  },
  modalDisableHeader: {
    id: 'atossa.togglemodal.disable.header',
    defaultMessage: 'Disable CJ Data?',
  },
  modalEnableBodyText: {
    id: 'atossa.togglemodal.enable.body',
    defaultMessage:
      'Please ensure you can accept the {cjdata} URL parameter on all landing pages where an affiliate may drive traffic to your website. By enabling this parameter, CJ will pass consent information that the Universal Tag will utilize to comply with the GDPR.',
  },
  modalDisableBodyText: {
    id: 'atossa.togglemodal.disable.body',
    defaultMessage:
      'By disabling this feature, CJ will no longer be able to pass GDPR consent information via the {cjdata} URL parameter that is required for GDPR compliance. Please confirm you want to disable this feature.',
  },
  modalConfirmButton: {
    id: 'atossa.togglemodal.toggle.button',
    defaultMessage: 'Confirm',
  },
  modalEnablingButton: {
    id: 'atossa.togglemodal.enabling.button',
    defaultMessage: 'Enabling...',
  },
  modalDisablingButton: {
    id: 'atossa.togglemodal.disabling.button',
    defaultMessage: 'Disabling...',
  },
  cjDataEnabledSuccessAlert: {
    id: 'atossa.togglemodal.enable.success.alert',
    defaultMessage: 'CJ Data has been enabled.',
  },
  cjDataEnabledFailAlert: {
    id: 'atossa.togglemodal.enable.fail.alert',
    defaultMessage: 'Enabling CJ Data failed.',
  },
  cjDataDisabledSuccessAlert: {
    id: 'atossa.togglemodal.disable.success.alert',
    defaultMessage: 'CJ Data has been disabled.',
  },
  cjDataDisabledFailAlert: {
    id: 'atossa.togglemodal.disable.fail.alert',
    defaultMessage: 'Disabling CJ Data failed.',
  },
});
