import {
  isTrackedValue,
  MaybeTrackedValue,
  TrackedValue,
  User,
} from 'atossa-core';

export interface DisplayTrackedValue<T> {
  value: T;
  updatedBy: string;
  updatedAt: string;
}

export const toDisplayTrackedValue: <T>(
  m: MaybeTrackedValue<T>
) => DisplayTrackedValue<T> = <T>(m: MaybeTrackedValue<T>) => {
  return isTrackedValue(m)
    ? displayTrackedValue(m)
    : {
        value: m.value,
        updatedAt: '',
        updatedBy: '',
      };
};

const readableEpoch = (n: number) => {
  return new Date(n).toLocaleDateString();
};

const readableUser = (u: User) => {
  return `${u.firstName} ${u.lastName}`.trim();
};

const displayTrackedValue = <T>(t: TrackedValue<T>) => ({
  value: t.value,
  updatedAt: readableEpoch(t.updatedAt),
  updatedBy: readableUser(t.updatedBy),
});

export const sanitize = <T, K extends keyof T>(object: T, fields: K[]): T => {
  const returnValue = { ...object };
  fields.forEach((field) => {
    if (typeof object[field] === 'string') {
      // @ts-ignore
      returnValue[field] = object[field].trim();
    }
  });

  return returnValue;
};

export const sanitizeNameUrl = (tag: { name?: string; url?: string }) => {
  return sanitize(tag, ['name', 'url']);
};
