const isError = (e: unknown): e is Error =>
  !!(e && typeof (e as Error).message === 'string');

export const errorMessage = (error: unknown): string => {
  if (typeof error === 'string') {
    return error;
  } else if (isError(error)) {
    return error.message;
  } else {
    return 'Unknown object thrown:' + JSON.stringify(error);
  }
};
