import { useEffect, useState } from 'react';
import { ErrorPage } from '@cjdev-internal/visual-stack-x/ErrorPage';
import { LoadingAnimation } from '@cjdev-internal/visual-stack-x/LoadingAnimation';
import * as R from 'ramda';
import { Api, HttpError } from 'Api/api';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { AdvertiserTagView } from '../AdvertiserTagView';
import { ModalState } from '../ModalMountPoint';
import {
  Advertiser,
  assertNever,
  MaybeTrackedValue,
  UniversalTag,
} from 'atossa-core';
import {
  CreateTagModalMountPoint,
  EditTagModalMountPoint,
  TagParams,
} from '../TagModal';
import { ToggleCjDataModalMountPoint } from '../ToggleCjDataModal';
import styled from 'styled-components';
import { BlankPage } from 'Components/BlankPage';
import { History } from 'history';
import { User } from '@platform/cj-platform-navigation';

const StyledDiv = styled.div`
  text-align: center;
`;

export interface ApiProps {
  api: Api;
}

export interface MainPageNewNavProps extends ApiProps {
  history: History<unknown>;
  doc: Document;
  user: User;
}
type PageState =
  | { state: 'Loading' }
  | { state: 'Error'; error: HttpError }
  | { state: 'Success' };

export interface Pagination {
  page: number;
  rowsPerPage: number;
}

const defaultPagination: Pagination = {
  page: 1,
  rowsPerPage: 10,
};

export const MainPageNewNav = (props: MainPageNewNavProps) => {
  const [pagination, setPagination] = useState(defaultPagination);
  const { api, user } = props;

  const cid = user.currentCompany.id.toString();
  const [advertiser, setAdvertiser] = useState<Advertiser>({
    cid,
    cjDataEnabled: { value: false },
    tags: [],
  });
  const setCjDataEnabled = (cjDataEnabled: MaybeTrackedValue<boolean>) => {
    setAdvertiser({ ...advertiser, cjDataEnabled });
  };
  const setTags = (tags: MaybeTrackedValue<UniversalTag>[]) => {
    setAdvertiser({ ...advertiser, tags });
  };
  const [pageState, setPageState] = useState<PageState>({
    state: 'Loading',
  });
  const [toggleCjDataModalState, setToggleCjDataModalState] =
    useState<ModalState>({
      state: 'Hidden',
    });
  const [createTagModalState, setCreateTagModalState] = useState<ModalState>({
    state: 'Hidden',
  });
  const [tagUnderEdit, setTagUnderEdit] = useState<TagParams>({
    name: '',
    url: '',
  });
  const [editTagModalState, setEditTagModalState] = useState<ModalState>({
    state: 'Hidden',
  });

  useEffect(() => {
    (async () => {
      setPageState({ state: 'Loading' });
      try {
        const advertiserPromise = api.fetchAdvertiser(cid);

        const [advertiser] = await Promise.all([advertiserPromise]);
        if (advertiser) {
          setAdvertiser(advertiser);
        }
        setPageState({ state: 'Success' });
      } catch (failure) {
        let error: HttpError;
        if (failure instanceof HttpError) {
          error = failure;
        } else if (failure instanceof Error) {
          error = new HttpError(failure.message);
        } else {
          error = new HttpError(String(failure));
        }
        console.log(failure);
        setPageState({ state: 'Error', error });
      }
    })();
  }, [api, cid]);

  interface ContentProps {
    pageState: PageState;
  }

  const MainContent = (contentProps: ContentProps) => {
    switch (contentProps.pageState.state) {
      case 'Loading':
        return (
          <StyledDiv>
            <LoadingAnimation
              loadingMessage={intl.formatMessage(
                content.tagTableLoadingMessage
              )}
            />
          </StyledDiv>
        );
      case 'Error':
        return (
          <ErrorPage errorCode={contentProps.pageState.error.statusCode} />
        );
      case 'Success':
        if (R.isNil(advertiser.tags) || !advertiser.tags.length) {
          return (
            <BlankPage
              openCreateTagModal={() =>
                setCreateTagModalState({ state: 'Open' })
              }
            />
          );
        } else {
          return (
            <AdvertiserTagView
              advertiser={advertiser}
              pagination={pagination}
              setPagination={setPagination}
              openToggleCjDataModal={() =>
                setToggleCjDataModalState({ state: 'Open' })
              }
              openCreateTagModal={() =>
                setCreateTagModalState({ state: 'Open' })
              }
              openEditTagModal={(tag: TagParams) => {
                setEditTagModalState({ state: 'Open' });
                setTagUnderEdit(tag);
              }}
            />
          );
        }
      default:
        assertNever(contentProps.pageState);
    }
  };
  return (
    <>
      <MainContent pageState={pageState} />
      <ToggleCjDataModalMountPoint
        modalState={toggleCjDataModalState}
        setModalState={setToggleCjDataModalState}
        cjDataEnabled={advertiser.cjDataEnabled}
        setCjDataEnabled={setCjDataEnabled}
        apiProps={{ api, cid }}
      />
      <CreateTagModalMountPoint
        modalState={createTagModalState}
        setModalState={setCreateTagModalState}
        setTags={setTags}
        apiProps={{ api, cid }}
      />
      <EditTagModalMountPoint
        modalState={editTagModalState}
        setModalState={setEditTagModalState}
        setTags={setTags}
        apiProps={{ api, cid }}
        tag={tagUnderEdit}
      />
    </>
  );
};

export const content = defineMessages({
  tagTableLoadingMessage: {
    id: 'atossa.tagTable.loading',
    defaultMessage: 'Loading',
  },
});
