import * as M from '@cjdev-internal/visual-stack/lib/components/Modal';
import { useState } from 'react';
import { Alert } from '@cjdev-internal/visual-stack/lib/components/Alert';
import styled from 'styled-components';
import { assertNever } from 'atossa-core';
import FocusTrap from 'focus-trap-react';

const StyledAlert = styled(Alert)`
  left: 50%;
  right: unset;
  width: unset;
  max-width: unset;
  transform: translate(-50%, 0);
  top: unset;
  bottom: 8vh;
`;

export type ModalState = { state: 'Open' } | { state: 'Hidden' };

export type AlertState =
  | { state: 'Success' }
  | { state: 'Failure' }
  | { state: 'Hidden' };

export interface ModalLabels {
  modalHeader: string;
  actButton: string;
  actingButton: string;
  alertSuccess: string;
  alertFail: string;
}

export interface ModalStateHandler<T> {
  modalFormState: T;
  setModalFormState: React.Dispatch<React.SetStateAction<T>>;
}

export interface ModalProps<T> {
  validate?: (current: T, initial: T) => boolean;
  labels: ModalLabels;
  initialModalFormState: T;
  action: (input: T) => Promise<void>;
  setModalState: (modalState: ModalState) => void;
  children?: (modalStateHandler: ModalStateHandler<T>) => JSX.Element;
}

export interface AlertModalProps<T> extends ModalProps<T> {
  setAlertState: (alertState: AlertState) => void;
}

interface ModalMountPointProps<T> {
  modalState: ModalState;
  modalProps: ModalProps<T>;
  ModalComponent: (props: AlertModalProps<T>) => JSX.Element;
}

export const ModalMountPoint = <T,>({
  modalState,
  modalProps,
  ModalComponent,
}: ModalMountPointProps<T>) => {
  const [alertState, setAlertState] = useState<AlertState>({ state: 'Hidden' });
  const alertModalProps = { ...modalProps, setAlertState };

  const AlertNode = (alertState: AlertState) => {
    switch (alertState.state) {
      case 'Success':
        setTimeout(() => {
          setAlertState({ state: 'Hidden' });
        }, 2000);
        return (
          <StyledAlert
            {...{
              children: modalProps.labels.alertSuccess,
              type: 'success',
              closeType: 'icon',
              onClose: () => {
                setAlertState({ state: 'Hidden' });
              },
            }}
          />
        );
      case 'Failure':
        return (
          <StyledAlert
            {...{
              children: modalProps.labels.alertFail,
              type: 'warning',
              closeType: 'icon',
              onClose: () => {
                setAlertState({ state: 'Hidden' });
              },
            }}
          />
        );
      case 'Hidden':
        return <div />;
      default:
        assertNever(alertState);
    }
  };
  switch (modalState.state) {
    case 'Open':
      return (
        <FocusTrap>
          <div>
            <ModalComponent {...alertModalProps} />
            <M.Backdrop />
            {AlertNode(alertState)}
          </div>
        </FocusTrap>
      );
    case 'Hidden':
      return AlertNode(alertState);
    default:
      assertNever(modalState);
  }
};
