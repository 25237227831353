import intl from '../../utils/intl';
import {
  BlankSlate,
  Description,
  PrimaryActionButton,
} from '@cjdev-internal/visual-stack/lib/components/BlankSlate';
import React from 'react';
import { defineMessages } from 'react-intl';

interface BlankPageProps {
  openCreateTagModal: () => void;
}

export const BlankPage = (props: BlankPageProps) => {
  return (
    <BlankSlate title={intl.formatMessage(content.blankPageHeader)}>
      <Description>{intl.formatMessage(content.blankPageText)}</Description>
      <PrimaryActionButton
        label={intl.formatMessage(content.createTagButton)}
        handler={props.openCreateTagModal}
      />
    </BlankSlate>
  );
};

export const content = defineMessages({
  blankPageHeader: {
    id: 'atossa.blankPage.header',
    defaultMessage:
      "Integrate CJ's transaction tags for accurate conversion tracking",
  },
  blankPageText: {
    id: 'atossa.blankPage.text',
    defaultMessage: 'Create your first tag to get started',
  },
  createTagButton: {
    id: 'atossa.blankPage.createtag.button',
    defaultMessage: 'Create Tag',
  },
});
