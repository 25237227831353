import React from 'react';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { Button } from '@cjdev-internal/visual-stack/lib/components/Button';
import { DataTable } from '@cjdev-internal/visual-stack/lib/components/Table/DataTable';
import { Advertiser, UniversalTag } from 'atossa-core';
import styled from 'styled-components';
import { CjDataView } from '../CjDataView';
import {
  DisplayTrackedValue,
  toDisplayTrackedValue,
} from '../../utils/trackedValue';
import { Pagination } from '../MainPage';
import { TagParams } from '../TagModal';

const CreateTagButtonDiv = styled.div`
  margin: 20px 20px 20px -4px;
  text-align: left;
`;

interface AdvertiserTagViewProps {
  advertiser: Advertiser;
  openToggleCjDataModal: () => void;
  openCreateTagModal: () => void;
  openEditTagModal: (tag: TagParams) => void;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
}

export const AdvertiserTagView = (props: AdvertiserTagViewProps) => {
  const { pagination, setPagination } = props;
  const emptyTagParams: TagParams = { name: '', url: '' };
  const formatTag = (tag: DisplayTrackedValue<UniversalTag>) => ({
    id: tag.value.id,
    row: [
      tag.value.id,
      tag.value.name || '',
      tag.value.url || '',
      tag.updatedAt,
      tag.updatedBy,
      <Button
        type="text-link"
        onClick={() =>
          props.openEditTagModal({ ...emptyTagParams, ...tag.value })
        }
      >
        {intl.formatMessage(content.editTagButton)}
      </Button>,
    ],
  });
  const data: {
    row: (string | JSX.Element)[];
  }[] = props.advertiser.tags.map((tag) =>
    formatTag(toDisplayTrackedValue(tag))
  );

  return (
    <>
      <CjDataView
        cjDataEnabled={toDisplayTrackedValue(props.advertiser.cjDataEnabled)}
        openToggleCjDataModal={props.openToggleCjDataModal}
      />
      <CreateTagButtonDiv>
        <Button type="solid-primary" onClick={props.openCreateTagModal}>
          {intl.formatMessage(content.createTagButton)}
        </Button>
      </CreateTagButtonDiv>
      <DataTable
        id="tag-data-table"
        caption={intl.formatMessage(content.tagTableCaption)}
        columns={[
          {
            label: intl.formatMessage(content.tagTableHeaderId),
            width: '10%',
          },
          {
            label: intl.formatMessage(content.tagTableHeaderName),
            width: '20%',
          },
          {
            label: intl.formatMessage(content.tagTableHeaderWebsiteUrl),
            width: '25%',
          },
          {
            label: intl.formatMessage(content.tagTableHeaderLastUpdated),
            width: '20%',
          },
          {
            label: intl.formatMessage(content.tagTableHeaderUpdatedBy),
            width: '15%',
          },
          {
            label: intl.formatMessage(content.tagTableHeaderActions),
            width: '10%',
          },
        ]}
        data={data}
        pagination
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onPageChange={setPagination}
        rowsPerPageTemplate={intl.formatMessage(
          content.tagTableDatatablePerPage,
          { 0: '{0}' }
        )}
        totalRecordsTemplate={intl.formatMessage(
          content.tagTableDatatableTotalRecords,
          { 0: '{0}' }
        )}
      />
    </>
  );
};

export const content = defineMessages({
  tagTableCaption: {
    id: 'atossa.tagTable.caption',
    defaultMessage: 'Tags',
  },
  tagTableHeaderId: {
    id: 'atossa.tagTable.table.header.id',
    defaultMessage: 'ID',
  },
  tagTableHeaderName: {
    id: 'atossa.tagTable.table.header.name',
    defaultMessage: 'Name',
  },
  tagTableHeaderWebsiteUrl: {
    id: 'atossa.tagTable.table.header.websiteUrl',
    defaultMessage: 'Website URL',
  },
  tagTableHeaderLastUpdated: {
    id: 'atossa.tagTable.table.header.lastUpdated',
    defaultMessage: 'Last Updated',
  },
  tagTableHeaderUpdatedBy: {
    id: 'atossa.tagTable.table.header.updatedBy',
    defaultMessage: 'Updated By',
  },
  tagTableHeaderActions: {
    id: 'atossa.tagTable.table.header.actions',
    defaultMessage: 'Actions',
  },
  tagTableDatatablePerPage: {
    id: 'atossa.tagTable.table.perPage',
    defaultMessage: '{0} per page',
  },
  tagTableDatatableTotalRecords: {
    id: 'atossa.tagTable.table.totalRecords',
    defaultMessage: '{0} total records',
  },
  createTagButton: {
    id: 'atossa.tagTable.createtag.button',
    defaultMessage: 'Create Tag',
  },
  editTagButton: {
    id: 'atossa.tagTable.edittag.button',
    defaultMessage: 'EDIT',
  },
});
